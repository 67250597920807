import { default as _91event_93mbwea0vpVRMeta } from "/home/bede/bede/pages/calendar/[event].vue?macro=true";
import { default as indexMHIxqD8kJwMeta } from "/home/bede/bede/pages/index.vue?macro=true";
import { default as bruksvilkarBGyXaF0GP5Meta } from "/home/bede/bede/pages/juridisk/bruksvilkar.vue?macro=true";
import { default as informasjonskapslerqD0cK7QkwvMeta } from "/home/bede/bede/pages/juridisk/informasjonskapsler.vue?macro=true";
import { default as personvernmFUzX5am4eMeta } from "/home/bede/bede/pages/juridisk/personvern.vue?macro=true";
import { default as kartzc6eI0abc6Meta } from "/home/bede/bede/pages/kart.vue?macro=true";
import { default as legg_45til_45kalenderr296R1AIVfMeta } from "/home/bede/bede/pages/legg-til-kalender.vue?macro=true";
import { default as om_45osshPinQgxhsPMeta } from "/home/bede/bede/pages/om-oss.vue?macro=true";
import { default as _91event_93cPP3xZEhwkMeta } from "/home/bede/bede/pages/program/[event].vue?macro=true";
import { default as indexfyl7oZCvdbMeta } from "/home/bede/bede/pages/program/index.vue?macro=true";
import { default as analyticsOZHtYaNlKyMeta } from "/home/bede/bede/pages/vuefire/analytics.vue?macro=true";
import { default as app_45checkGUGez53iz0Meta } from "/home/bede/bede/pages/vuefire/app-check.vue?macro=true";
import { default as counterFkBcl7NU2OMeta } from "/home/bede/bede/pages/vuefire/counter.vue?macro=true";
import { default as database_45todo_45listSyoicWYQDnMeta } from "/home/bede/bede/pages/vuefire/database-todo-list.vue?macro=true";
import { default as emoji_45panelRKkemDwnC2Meta } from "/home/bede/bede/pages/vuefire/emoji-panel.vue?macro=true";
import { default as indexBLyL3awsuMMeta } from "/home/bede/bede/pages/vuefire/index.vue?macro=true";
import { default as loginJjesdflFGUMeta } from "/home/bede/bede/pages/vuefire/login.vue?macro=true";
import { default as newwF0c9ThPKSMeta } from "/home/bede/bede/pages/vuefire/posts/new.vue?macro=true";
import { default as storageWTXCgn8QuvMeta } from "/home/bede/bede/pages/vuefire/storage.vue?macro=true";
import { default as usersmtGH4dWAiMMeta } from "/home/bede/bede/pages/vuefire/users.vue?macro=true";
export default [
  {
    name: "calendar-event",
    path: "/calendar/:event()",
    component: () => import("/home/bede/bede/pages/calendar/[event].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/bede/bede/pages/index.vue")
  },
  {
    name: "juridisk-bruksvilkar",
    path: "/juridisk/bruksvilkar",
    component: () => import("/home/bede/bede/pages/juridisk/bruksvilkar.vue")
  },
  {
    name: "juridisk-informasjonskapsler",
    path: "/juridisk/informasjonskapsler",
    component: () => import("/home/bede/bede/pages/juridisk/informasjonskapsler.vue")
  },
  {
    name: "juridisk-personvern",
    path: "/juridisk/personvern",
    component: () => import("/home/bede/bede/pages/juridisk/personvern.vue")
  },
  {
    name: "kart",
    path: "/kart",
    component: () => import("/home/bede/bede/pages/kart.vue")
  },
  {
    name: "legg-til-kalender",
    path: "/legg-til-kalender",
    component: () => import("/home/bede/bede/pages/legg-til-kalender.vue")
  },
  {
    name: "om-oss",
    path: "/om-oss",
    component: () => import("/home/bede/bede/pages/om-oss.vue")
  },
  {
    name: "program-event",
    path: "/program/:event()",
    component: () => import("/home/bede/bede/pages/program/[event].vue")
  },
  {
    name: "program",
    path: "/program",
    component: () => import("/home/bede/bede/pages/program/index.vue")
  },
  {
    name: "vuefire-analytics",
    path: "/vuefire/analytics",
    meta: analyticsOZHtYaNlKyMeta || {},
    component: () => import("/home/bede/bede/pages/vuefire/analytics.vue")
  },
  {
    name: "vuefire-app-check",
    path: "/vuefire/app-check",
    meta: app_45checkGUGez53iz0Meta || {},
    component: () => import("/home/bede/bede/pages/vuefire/app-check.vue")
  },
  {
    name: "vuefire-counter",
    path: "/vuefire/counter",
    meta: counterFkBcl7NU2OMeta || {},
    component: () => import("/home/bede/bede/pages/vuefire/counter.vue")
  },
  {
    name: "vuefire-database-todo-list",
    path: "/vuefire/database-todo-list",
    meta: database_45todo_45listSyoicWYQDnMeta || {},
    component: () => import("/home/bede/bede/pages/vuefire/database-todo-list.vue")
  },
  {
    name: "vuefire-emoji-panel",
    path: "/vuefire/emoji-panel",
    meta: emoji_45panelRKkemDwnC2Meta || {},
    component: () => import("/home/bede/bede/pages/vuefire/emoji-panel.vue")
  },
  {
    name: "vuefire",
    path: "/vuefire",
    component: () => import("/home/bede/bede/pages/vuefire/index.vue")
  },
  {
    name: "vuefire-login",
    path: "/vuefire/login",
    meta: loginJjesdflFGUMeta || {},
    component: () => import("/home/bede/bede/pages/vuefire/login.vue")
  },
  {
    name: "vuefire-posts-new",
    path: "/vuefire/posts/new",
    meta: newwF0c9ThPKSMeta || {},
    component: () => import("/home/bede/bede/pages/vuefire/posts/new.vue")
  },
  {
    name: "vuefire-storage",
    path: "/vuefire/storage",
    meta: storageWTXCgn8QuvMeta || {},
    component: () => import("/home/bede/bede/pages/vuefire/storage.vue")
  },
  {
    name: "vuefire-users",
    path: "/vuefire/users",
    meta: usersmtGH4dWAiMMeta || {},
    component: () => import("/home/bede/bede/pages/vuefire/users.vue")
  }
]