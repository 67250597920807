export default {
  bede: "Bede",
  menu: "Meny",
  menuAria: "Vis eller skjul menyen",
  homeLink: "Hjem",
  calendarLink: "Program",
  mapLink: "Kart",
  aboutLink: "Om oss",
  event: "Arrangement",
  about: "om",
  intro: "Hva skjer?",
  coordinator: "Koordinator",
  organizer: "Arrangør",
  moreEvents: "Se videre program",
  importCalendar: "Mobilkalender",
  importCalendarLink: "Mobilkalender",
  shareMessage:
    "En lenke til dette arrangementet er kopiert til utklippstavlen.",
  print: "🖨",
  expandAll: "Fyldig",
  collapseAll: "Sammendrag",
  attendingDefinite: "Kommer",
  attendingMaybe: "Kanskje",
};
