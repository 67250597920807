<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script lang="ts" setup>
import {
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { updateProfile } from "firebase/auth";

import Location from "./data/Location";
import PlainI18nNO from "@/data/I18nNO.js";
const I18nNO = reactive(PlainI18nNO);

const title = computed(() => `${Location.locationName} | ${I18nNO.bede}`);

useHead({
  titleTemplate: (title) => `${title} | ${I18nNO.bede}`,
  title: Location.locationName,
  meta: [
    {
      name: "title",
      content: title,
    },
    {
      name: "description",
      content:
        "Hva skjer på Kongsvinger Bedehus? Aktiviteter, programoversikt, hvem er vi.",
    },
    {
      property: "og:site_name",
      content: Location.locationName,
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      property: "og:title",
      content: title,
    },
    {
      property: "og:description",
      content:
        "Hva skjer på Kongsvinger Bedehus? Aktiviteter, programoversikt, hvem er vi.",
    },
    {
      property: "og:image",
      content: "https://bede.no/img/bedehuset-fb-bd-n.jpg",
    },
    {
      property: "og:locale",
      content: `no_NO`,
    },
    {
      name: "twitter:card",
      content: "summary_large_image",
    },
    {
      name: "twitter:title",
      content: title,
    },
    {
      name: "twitter:description",
      content:
        "Hva skjer på Kongsvinger Bedehus? Aktiviteter, programoversikt, hvem er vi.",
    },
    {
      name: "twitter:image",
      content: "https://bede.no/img/bedehuset-fb-bd-n.jpg",
    },
  ],
  htmlAttrs: {
    lang: "no",
    //   class: "dark",
  },
});

const cookieConsentState = useCookieConsent();
const consentCookie = useCookie("CookieConsent", {
  secure: true,
  sameSite: "strict",
  maxAge: 60 * 60 * 24 * 365,
});
if (consentCookie.value) {
  cookieConsentState.value = consentCookie.value;
}
watch(cookieConsentState, (to) => {
  consentCookie.value = to;
});

const userState = useUserState();
const userCookie = useCookie("UserIdRandomString", {
  secure: true,
  sameSite: "strict",
  maxAge: 60 * 60 * 24 * 365,
});
watch(userState, (to) => {
  userCookie.value = to;
});
if (userCookie.value) {
  userState.value = userCookie.value;
}
if (!userState.value) {
  userState.value = crypto.randomUUID();
  userCookie.value = userState.value;
}
</script>

<style>
html,
body {
  height: 100%;
  background-color: #eee;
}
@media print {
  html,
  body {
    height: 100%;
    background-color: #fff;
  }
  @page {
    margin: 2rem;
  }
}
#__nuxt {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
