import type Location from "@/types/Location";

export default {
  locationName: "Kongsvinger Bedehus",
  img: "/img/bedehuset-fb-bd-n.jpg",
  imgAlt: "Kongsvinger Bedehus",
  imgCaptionHref:
    "https://www.facebook.com/bdungdomsklubb/photos/280983505356200",
  imgCaptionName: "BD Ungdomsklubb",
  history: "",
  about: [
    [
      { h2: "Bedehuset" },
      {
        text: "Kongsvinger bedehus ligger midt i sentrum av Kongsvinger i Tommelstadsgate 1 vis-a-vis Kongssenteret. Bedehuset eies av Normisjon og ",
      },
      {
        link: {
          label: "stod ferdig bygget",
          href: "https://www.nb.no/items/9c0eda75c447b89d0984b20e7d1e2cba?page=191",
          external: true,
        },
      },
      { text: " til innvielse " },
      {
        link: {
          label: "søndag 18. november 1877",
          href: "https://www.nb.no/items/URN:NBN:no-nb_digibok_2011111808057?page=365",
          external: true,
        },
      },
      { text: "." },
    ],
    [
      { h2: "Kongsvinger Misjonsforsamling" },
      {
        text: "Blant aktørene som arrangerer samlinger på Bedehuset finner vi Kongsvinger Misjonsforsamling (KMF) - et åpent, raust og forpliktende samarbeid mellom lokale misjonsforeninger tilknyttet de tre misjonsorganisasjonene ",
      },
      {
        link: {
          label: "Normisjon",
          href: "https://www.normisjon.no/vart-arbeid",
          external: true,
        },
      },
      { text: ", " },
      {
        link: {
          label: "Misjonsselskapet (NMS)",
          href: "https://nms.no/om-oss",
          external: true,
        },
      },
      { text: " og " },
      {
        link: {
          label: "Misjonssambandet (NLM)",
          href: "https://nlm.no/om-oss",
          external: true,
        },
      },
      {
        text: ". Misjonsforsamlingen er i sitt arbeid forpliktet på den Lutherske bekjennelse.",
      },
    ],
    [
      {
        quote:
          "Kongsvinger Misjonsforsamling vil arbeide for Guds rikes utbredelse lokalt og globalt. For å virkeliggjøre dette vil vi sette fokus på følgende: Et åpent og inkluderende fellesskap med omsorg for alle, forkynnelse av og undervisning i Guds Ord for å nære og styrke det åndelige liv i alle aldersgrupper (barn, ungdom og voksne), utadrettet virksomhet for å vinne mennesker for Jesus Kristus og inn i et kristent fellesskap, samt informasjon, inspirasjon og innsamling av midler til misjonsarbeidet hjemme og ute.",
      },
    ],
    [
      {
        text: "Den første søndagen hver måned samles det til Praise og den nest siste søndagen hver måned blir det organisert misjonsmøter. To onsdager i måneden holdes det også samlinger med undervisning eller film, med tilhørende samtale.",
      },
    ],
    [
      { h2: "KingKids" },
      {
        link: {
          label: "KingKids",
          href: "https://www.facebook.com/vingerkingkids",
          external: true,
        },
      },
      {
        text: " - gospelkor for barn og ungdom i Glåmdalsdistriktet. Øvelse med lek og moro hver tirsdag fra 17.00-18.00 i skoleukene.",
      },
    ],
    [
      { h2: "KingTeens" },
      {
        text: "Ungdomskoret KingTeens er for ungdommer mellom 13-19 år. Vi øver på Kongsvinger bedehus hver tirsdag fra 18:15-19:15. Alle er velkommen!",
      },
    ],
    [
      { h2: "Ungdomsklubben BD" },
      {
        link: {
          label: "BD",
          href: "https://www.facebook.com/bdungdomsklubb",
          external: true,
        },
      },
      {
        text: " - ungdomsklubben på Bedehuset - er åpen hver torsdag kl 18:00-22:00 (stengt ved skolefri). Klubben drives av ",
      },
      {
        link: {
          label: "Acta - Barn og unge i Normisjon",
          href: "https://acta.no/om-acta",
          external: true,
        },
      },
      {
        text: " og Frivilligsentralen Kongsvinger Nærmiljøsenter. På klubben kan du henge med venner og ganske sikkert få noen nye. Vi har slush, og du kan spille biljard eller bordtennis frem til kl. 19:00 - da er det samling, med 'ord for kvelden' og quiz. Til slutt spiser vi noe godt sammen.",
      },
    ],
    [
      { h2: "Tacofredag" },
      {
        text: "Fredagen før første søndag i måneden samles vi til tacofredag i regi av Nærmiljøsenteret. Kom og nyt taco, utlodning, quiz og godt fellesskap.",
      },
    ],
    [
      { h2: "Sangkafé" },
      {
        text: "Nærmiljøsenteret arrangerer sangkafé med artister fra fjern og nær en av de siste fredagene i måneden klokka 12.00.",
      },
    ],
  ],
} as Location;
