import payload_plugin_pGHs5ixvX1 from "/home/bede/bede/node_modules/.pnpm/nuxt-vuefire@1.0.4_@firebase+app-types@0.9.2_firebase-admin@12.7.0_encoding@0.1.13__firebase-_3wmqifgww6cqtitcboaaeytxxa/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_Z2ev7afuUF from "/home/bede/bede/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_encoding@0.1.13_ioredis@5.4.1_magicast_epjzvhmz2vwln7hmw4scymg3zu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_q8IpfMkBwt from "/home/bede/bede/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_encoding@0.1.13_ioredis@5.4.1_magicast_epjzvhmz2vwln7hmw4scymg3zu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_JlScLlIlEu from "/home/bede/bede/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_encoding@0.1.13_ioredis@5.4.1_magicast_epjzvhmz2vwln7hmw4scymg3zu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import navigation_repaint_client_tgESKPdEhG from "/home/bede/bede/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_encoding@0.1.13_ioredis@5.4.1_magicast_epjzvhmz2vwln7hmw4scymg3zu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_tM86SiOW78 from "/home/bede/bede/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_encoding@0.1.13_ioredis@5.4.1_magicast_epjzvhmz2vwln7hmw4scymg3zu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_4RmZbaDogR from "/home/bede/bede/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_encoding@0.1.13_ioredis@5.4.1_magicast_epjzvhmz2vwln7hmw4scymg3zu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/bede/bede/.nuxt/components.plugin.mjs";
import prefetch_client_gU15VP1zRH from "/home/bede/bede/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_encoding@0.1.13_ioredis@5.4.1_magicast_epjzvhmz2vwln7hmw4scymg3zu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_cRopfR7j0P from "/home/bede/bede/node_modules/.pnpm/nuxt-vuefire@1.0.4_@firebase+app-types@0.9.2_firebase-admin@12.7.0_encoding@0.1.13__firebase-_3wmqifgww6cqtitcboaaeytxxa/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_auth_plugin_client_cCwkwh1j5u from "/home/bede/bede/.nuxt/vuefire-auth-plugin.client.mjs";
import vuefire_plugin_8cq4FnzXKb from "/home/bede/bede/.nuxt/vuefire-plugin.mjs";
import plugin_client_KfcFNdoGr5 from "/home/bede/bede/node_modules/.pnpm/nuxt-vuefire@1.0.4_@firebase+app-types@0.9.2_firebase-admin@12.7.0_encoding@0.1.13__firebase-_3wmqifgww6cqtitcboaaeytxxa/node_modules/nuxt-vuefire/dist/runtime/app-check/plugin.client.js";
import analytics_client_mtqVw3YRXk from "/home/bede/bede/plugins/analytics.client.ts";
import vFocus_TH5s1YuUk1 from "/home/bede/bede/plugins/vFocus.ts";
export default [
  payload_plugin_pGHs5ixvX1,
  revive_payload_client_Z2ev7afuUF,
  unhead_q8IpfMkBwt,
  router_JlScLlIlEu,
  navigation_repaint_client_tgESKPdEhG,
  check_outdated_build_client_tM86SiOW78,
  chunk_reload_client_4RmZbaDogR,
  components_plugin_KR1HBZs4kY,
  prefetch_client_gU15VP1zRH,
  plugin_client_cRopfR7j0P,
  vuefire_auth_plugin_client_cCwkwh1j5u,
  vuefire_plugin_8cq4FnzXKb,
  plugin_client_KfcFNdoGr5,
  analytics_client_mtqVw3YRXk,
  vFocus_TH5s1YuUk1
]